import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ConsentDataService} from '../../services/consent-data.service';
import {MarkdownService} from 'ngx-markdown';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';
import {ConsentService} from '../../services/consent.service';
import {HttpbaseService} from '../../services/httpbase.service';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsentComponent implements OnInit {

  private uuid: string;
  consentText: string | Promise<string>;
  isLoading: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private consentService: ConsentService,
              private httpClient: HttpClient,
              public consentDataService: ConsentDataService,
              private httpbaseService: HttpbaseService,
              private markdownService: MarkdownService,
              private translate: TranslateService,
              private location: Location) {
    this.isLoading = true;
  }

  // DC-197 - find right solution
  ngOnInit() {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getConsentText(this.uuid);
  }

  getConsentText(uuid: string) {
    this.consentService.getConsentText(uuid).subscribe(value => {
      this.isLoading = false;
      this.consentDataService.getConsentText().consentText = value.consentText;
      this.consentDataService.getConsentText().createdTs = value.createdTs;
      this.consentDataService.getConsentText().version = value.version;
      this.consentDataService.getConsentText().language = value.language;
      this.translate.use(this.consentDataService.getConsentText().language);
      this.consentText = this.markdownService.parse(this.consentDataService.getConsentText().consentText);
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.CONSENT_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }

  backLink() {
    this.location.back();
  }
}
