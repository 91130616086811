import {Component, DoCheck, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsentService} from '../../services/consent.service';
import {ConsentDataService} from '../../services/consent-data.service';
import {RequestStatusEnum} from '../../data/requestStatusEnum';
import {MarkdownService} from 'ngx-markdown';
import {HttpbaseService} from '../../services/httpbase.service';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../../data/language';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoctorComponent implements OnInit, DoCheck {
  private uuid: string;
  consentAccepted: boolean;
  private acceptedStatuses: RequestStatusEnum[] = [RequestStatusEnum.CONSENT_GIVEN, RequestStatusEnum.REQUEST_DONE,
    RequestStatusEnum.CONSENT_REPORTED];
  consentText: string | Promise<string>;
  languages: Language[] = Language.LANGUAGES;
  selectedLanguageCode: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private consentService: ConsentService,
              public consentDataService: ConsentDataService,
              private markdownService: MarkdownService,
              private httpbaseService: HttpbaseService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getConsent(this.uuid);
  }

  ngDoCheck() {
    if (this.consentDataService.getDoctorData().language !== undefined) {
      this.translate.use(this.consentDataService.getDoctorData().language);
    }
  }

  getConsent(uuid: string) {
    this.consentService.getDoctorData(uuid).subscribe(value => {
      this.consentDataService.getDoctorData().casenumber = value.casenumber;
      this.consentDataService.getDoctorData().caseCreatedDate = value.caseCreatedDate;
      this.consentDataService.getDoctorData().company = value.company;
      this.consentDataService.getDoctorData().consentStatus = value.consentStatus;
      this.consentDataService.getDoctorData().doctorUUID = value.doctorUUID;
      this.consentDataService.getDoctorData().consentText = value.consentText;
      this.consentDataService.getDoctorData().acceptedDate = value.acceptedDate;
      this.consentDataService.getDoctorData().language = value.language;
      this.selectedLanguageCode = value.language;
      console.log(this.consentDataService.getDoctorData());
      this.consentAccepted = this.acceptedStatuses.includes(this.consentDataService.getDoctorData().consentStatus);
      this.consentText = this.markdownService.parse(this.consentDataService.getDoctorData().consentText);
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.DOCTOR_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }

  onSelectLanguageEvent() {
    console.log('language clicked: ' + this.selectedLanguageCode);
    this.consentService.getDoctorDataWithLanguage(this.uuid, this.selectedLanguageCode).subscribe(value => {
      this.consentDataService.getDoctorData().language = this.selectedLanguageCode;
      this.consentDataService.getDoctorData().consentText = value.consentText;
      this.consentText = this.markdownService.parse(this.consentDataService.getDoctorData().consentText);
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.DOCTOR_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }
}
